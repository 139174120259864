import React from 'react'
import ContactForm from '../../Components/ContactForm/ContactForm'

function Contact() {
  return (
    <div>
        <ContactForm />
    </div>
  )
}

export default Contact