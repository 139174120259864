import React from 'react'
import About from '../../Sections/About/About'

function AboutPage() {
  return (
    <div>
        <About />
    </div>
  )
}

export default AboutPage